import React, { useEffect, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Skeleton } from 'antd'

import Header from './Header'
import { APP } from '@/redux/actionTypes'
import { RootState } from '@/redux/store'
import { useAppDispatch, useAppSelector } from '@/utils/hooks'

interface Props {
  routes: Routes
}

const Layout: React.FC<Props> = ({ routes, ...rest }) => {
  const dispatch = useAppDispatch()
  const { userInfo, currentEntity, entityIcon } = useAppSelector((state: RootState) => state.app)

  useEffect(() => {
    if (!userInfo.userId) {
      queryUserInfo()
    }
  }, [userInfo])

  useEffect(() => {
    if (currentEntity.entityId) {
      queryEntityIcon()
      queryUserResources()
      queryBrandList()
    }
  }, [currentEntity])

  useEffect(() => {
    if (entityIcon) {
      setLinkIcon(entityIcon)
    }
  }, [entityIcon])

  const queryBrandList = () => {
    dispatch({ type: APP.GET_ENTITY_BRANDLIST, payload: { entityId: currentEntity.entityId } })
  }

  const queryUserInfo = () => {
    dispatch({ type: APP.GET_USER_INFO })
  }

  const queryUserResources = () => {
    dispatch({ type: APP.GET_USER_RESOURCES, payload: { entityId: currentEntity.entityId } })
  }

  const queryEntityIcon = () => {
    dispatch({ type: APP.GET_ENTITY_ICON, payload: { entityId: currentEntity.entityId } })
  }

  const setLinkIcon = (url: string) => {
    if (url) {
      const link: any = document.getElementById('iconLink')
      link.href = url
      // if (!link.getAttribute('href')) {
      //   link.href = url
      // }
    }
  }

  return (
    <div className="layout">
      <Header></Header>
      <Suspense fallback={<Skeleton active></Skeleton>}>
        <Switch>
          {routes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              render={props => (
                <route.component {...props} {...rest} route={route} routes={route.routes} />
              )}
            />
          ))}
        </Switch>
      </Suspense>
    </div>
  )
}

export default Layout
