import { put, takeEvery, takeLatest } from 'redux-saga/effects'

import call from '../call'
import { delay, setAegisOpt, lsSetItem } from '@/utils/util'
import { APP, LOADING } from '../actionTypes'
import { userInfo, userResources, entityIcon, updateEntityConfigInfo } from '../reducers/app'
import { loadingSuccess } from '../reducers/loading'

import {
  getUserInfoService,
  getUserResourcesService,
  logoutService,
  getEntityIconService,
  getEntityBrandListService,
} from '../service/app'

function* getUserInfo(action: Action) {
  const res: ResponseData = yield call(getUserInfoService, action)
  if (res && res?.code === 0) {
    setAegisOpt({ uin: res.data.userAccount + '/' + res.data.userName })
    yield put(userInfo(res.data))
  }
}

function* getEntityIcon(action: Action) {
  const res: ResponseData = yield call(getEntityIconService, action)
  if (res && res?.code === 0) {
    yield put(entityIcon(res.data))
  }
}

function* getUserResources(action: Action) {
  const res: ResponseData = yield call(getUserResourcesService, action, {
    type: LOADING.RESOURCE,
  })
  if (res && res.code === 0 && res.data) {
    yield put(userResources(res.data))
  }
  yield put(loadingSuccess({ type: LOADING.RESOURCE, success: res.code === 0 }))
}

function* logout(action: Action) {
  const res: ResponseData = yield call(logoutService, action)
  if (res && res.code === 0) {
    yield put(userInfo({ isLogout: true }))
    yield lsSetItem('__fallBack__', location.href)
    location.hash = '/login'
    yield delay(200)
    yield put(userInfo({}))
    yield put(userResources([]))
  }
}

function* getEntityBrandList(action: Action) {
  const res: ResponseData = yield call(getEntityBrandListService, action)
  if (res?.code === 0 && res.data) {
    yield put(updateEntityConfigInfo({ brandList: res.data }))
  }
}

export default function* root() {
  yield takeEvery(APP.GET_USER_INFO, getUserInfo)
  yield takeEvery(APP.GET_ENTITY_ICON, getEntityIcon)
  yield takeLatest(APP.GET_USER_RESOURCES, getUserResources)
  yield takeLatest(APP.GET_ENTITY_BRANDLIST, getEntityBrandList)
  yield takeEvery(APP.LOG_OUT, logout)
}
