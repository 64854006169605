import React, { Suspense, useEffect, useRef } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Skeleton, Card } from 'antd'
import moment from 'moment'

import ManageAside from './ManageAside'
import { IBreadcrumb } from '@/components'
import { getFirstRoutePath, applyWatermark } from '@/utils/util'
import { useAppSelector } from '@/utils/hooks'

interface Props {
  route: Routes[number]
  routes: Routes
}

const ManageLayout: React.FC<Props> = ({ route, routes, ...rest }) => {
  const { userInfo } = useAppSelector(state => state.app)
  const watermarkRef = useRef(null)
  const firstPath = getFirstRoutePath(routes)
  const redirectPath = !firstPath || firstPath === '*' ? '/notFound' : firstPath
  const fromPath = /(\/[A-z]+)/.exec(location.hash)?.[0]

  useEffect(() => {
    if (userInfo.userId && watermarkRef.current) {
      const { userAccount, ip } = userInfo
      const date = moment().format('YY/M/D')
      const text = `${userAccount} IP ${ip} ${date}`
      applyWatermark(watermarkRef.current, text, {
        color: 'rgba(0, 0, 0, 0.06)',
        fontSize: '24px',
        angle: -20,
        width: 684,
        height: 400,
      })
    }
  }, [userInfo, watermarkRef])

  const renderRoutes = (routes: Routes): React.ReactNode => {
    if (!routes?.length) {
      return null
    }
    return routes.map(route =>
      route.component ? (
        <Route exact={true} key={route.path} path={route.path}>
          <route.component {...rest}>{route.routes && renderRoutes(route.routes)}</route.component>
        </Route>
      ) : (
        route.routes && renderRoutes(route.routes)
      )
    )
  }

  return (
    <div className="manage-layout">
      <div className="water-mark" ref={watermarkRef}></div>
      <ManageAside routes={routes}></ManageAside>
      <div className="manage-layout-main">
        <IBreadcrumb route={route}></IBreadcrumb>
        <Suspense fallback={<Skeleton active></Skeleton>}>
          <Card className="manage-layout-main-card">
            <Switch>
              <Redirect exact from={fromPath} to={redirectPath} />
              {renderRoutes(routes)}
            </Switch>
          </Card>
        </Suspense>
      </div>
    </div>
  )
}

export default ManageLayout
