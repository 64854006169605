import request from '@/utils/request'

export const queryCityInfo = (data: RequestData) => {
  return request('dataCenterQueryCityInfo', {
    data,
  })
}

export const queryAreaRegion = (data: RequestData) => {
  return request('dataCenterQueryAreaRegion', {
    data,
  })
}

export const queryBikeInfo = (data: RequestData) => {
  return request('dataCenterQueryBikeInfo', {
    data,
  })
}

export const querySubwayBikeInfo = (data: RequestData) => {
  return request('dataCenterQuerySubwayBikeInfo', {
    data,
  })
}

export const querySearchBike = (data: RequestData) => {
  return request('dataCenterSearchBike', {
    data,
  })
}

export const queryParkingAreaInfo = (data: RequestData) => {
  return request('dataCenterParkingAreaInfo', {
    data,
  })
}

export const querySearchParkingArea = (data: RequestData) => {
  return request('dataCenterSearchParkingArea', {
    data,
  })
}

export const queryHeatMap = (data: RequestData) => {
  return request('dataCenterQueryHeatMap', {
    data,
  })
}

export const querySniffHeatMap = (data: RequestData) => {
  return request('dataCenterQuerySniffHeatMap', {
    data,
  })
}

export const queryOperationInfo = (data: RequestData) => {
  return request('dataCenterQueryOperationInfo', {
    data,
  })
}

export const querySearchOperationInfo = (data: RequestData) => {
  return request('dataCenterQuerySearchOperationList', {
    data,
  })
}

export const queryKeyAreaInfo = (data: RequestData) => {
  return request('patrolCenterQueryKeyAreaInfo', {
    data,
  })
}

export const queryCameraInfo = (data: RequestData) => {
  return request('dataCenterQueryCameraInfo', {
    data,
  })
}
