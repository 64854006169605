import { lazy } from 'react'
import { filterRoutes } from '../utils/util'
import { getManageCenterRoutes } from './manageRoutes'

import type { ResourceMap } from '../utils/consts'

const dataCenterRoutes = [
  {
    path: '/dataCenter',
    component: lazy(() => import('../pages/dataCenter')),
  },
]

const getSubwayCenterRoutes = (subwayCenterRs: ResourceMap['subwayCenter']) => {
  const routes = [
    {
      path: '/subwayCenter',
      component: () => {
        if (!subwayCenterRs.index) {
          const NotFound = require('../pages/notFound').default
          return <NotFound></NotFound>
        }
        const SubwayCenter = require('../pages/subwayCenter/index.tsx').default
        return <SubwayCenter></SubwayCenter>
      },
    },
  ]
  return routes
}

const getRoutes = (resourceMap: ResourceMap, userInfo: UserInfo): Routes => {
  const { manageCenter, subwayCenter } = resourceMap
  const subwayCenterRoutes = getSubwayCenterRoutes(subwayCenter)
  const manageCenterRoutes = getManageCenterRoutes(manageCenter, userInfo)
  const routes = [
    {
      path: '/login',
      component: lazy(() => import('../pages/login')),
    },
    {
      path: '/',
      component: require('../app/Layout').default,
      routes: [
        ...dataCenterRoutes,
        ...subwayCenterRoutes,
        ...manageCenterRoutes,
        { path: '*', component: require('../pages/notFound').default },
      ],
    },
  ]
  return filterRoutes(routes as Routes)
}

export default getRoutes
