export const APP = {
  GET_USER_INFO: 'APP_GET_USER_INFO',
  GET_ENTITY_ICON: 'APP_GET_ENTITY_ICON',
  GET_USER_RESOURCES: 'APP_GET_USER_RESOURCES',
  GET_ENTITY_BRANDLIST: 'APP_GET_ENTITY_BRANDLIST',
  LOG_OUT: 'APP_LOG_OUT',
}

export const LOADING = {
  RESOURCE: 'RESOURCE',
}

export const DATACENTER = {
  QUERY_CITY_INFO: 'DATACENTER_QUERY_CITY_INFO',
  QUERY_BIKE_INFO: 'DATACENTER_QUERY_BIKE_INFO',
  QUERY_HEAT_MAP: 'DATACENTER_QUERY_HEAT_MAP',
  QUERY_SNIFF_HEAT_MAP: 'DATACENTER_QUERY_SNIFF_HEAT_MAP',
  QUERY_SUBWAY_BIKE_INFO: 'DATACENTER_QUERY_SUBWAY_BIKE_INFO',
  QUERY_AREA_REGION: 'DATACENTER_QUERY_AREA_REGION',
  QUERY_SEARCH_BIKE: 'DATACENTER_QUERY_SEARCH_BIKE',
  QUERY_PARKING_AREA_INFO: 'DATACENTER_QUERY_PARKING_AREA_INFO',
  QUERY_SEARCH_PARKING_AREA: 'DATACENTER_QUERY_SEARCH_PARKING_AREA',
  QUERY_OPERATION_INFO: 'DATACENTER_QUERY_OPERATION_INFO',
  QUERY_SEARCH_OPERATION_INFO: 'DATACENTER_QUERY_SEARCH_OPERATION_INFO',
  QUERY_KEY_AREA_INFO: 'DATACENTER_QUERY_KEY_AREA_INFO',
  QUERY_CAMERA_INFO: 'DATACENTER_QUERY_CAMERA_INFO',
}

export const PATROLCENTER = {
  QUERY_CITY_INFO: 'PATROLCENTER_QUERY_CITY_INFO',
  QUERY_AREA_REGION: 'PATROLCENTER_QUERY_AREA_REGION',
  QUERY_KEY_AREA_INFO: 'PATROLCENTER_QUERY_KEY_AREA_INFO',
  QUERY_PARKING_AREA_INFO: 'PATROLCENTER_QUERY_PARKING_AREA_INFO',
  QUERY_SEARCH_PARKING_AREA: 'PATROLCENTER_QUERY_SEARCH_PARKING_AREA',
}
